import React from 'react'
import Button from '../Button/Button';
import { useNavigate } from 'react-router-dom';
import styles from './Content.module.scss';

export default function Content() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/projects');
}

    return (
        <div className={styles.contentContainer}>
          <h1>Crafting Digital Journeys.</h1>
          <p>
            From pixel-perfect Designs to seamless code, bridging the gap between
            vision and reality for a dynamic web experience
          </p>
          <Button name='View Projects' onClick={handleClick} />
        </div>
      );
}
