import nos from "../images/nos.png"
import nos_design from "../images/nos_design.png";
import nos_design1 from "../images/nos_design1.png";
import time_mgmt from "../images/time_mgmt.png";
import time_mgmt_design from "../images/time_mgmt_design.png";
import time_mgmt_design1 from "../images/time_mgmt_design1.png";
import teal_editions from "../images/teal.png";
import teal_design from "../images/teal_design.png";
import teal_design1 from "../images/teal_design1.png";
import alter from "../images/alter.png";
import alter_design from "../images/alter_design.png";
import alter_design1 from "../images/alter_design1.png";
import mp from "../images/maria_pino.jpg";
import mp_design from "../images/mp_design.png";
import mp_design1 from "../images/mp_design1.png";
import mlg from "../images/mlg.png";
import mlg_design from '../images/mlg_design.png';
import mlg_design1 from '../images/mlg_design1.png';
import lamin_air from "../images/lamin_air.jpg";
import laminair_design from "../images/laminair_design.png";
import laminair_design1 from "../images/laminair_design1.png";
import linkera from "../images/linkera.png";
import linkera_design from "../images/linkera_design.png";
import linkera_design1 from "../images/linkera_design1.png";
import artkom from "../images/artkom.jpeg";
import artkom_design from "../images/artkom_design.png";
import artkom_design1 from "../images/artkom_design1.png";

export const projects = [
  
  {
    id: 1,
        title: "Alter Designs",
    description: "Website Development",
    image: alter,
    link: "/alter-designs",
    overview: 'Alter Designs is a fashion brand created with a strong commitment to promoting a more responsible and inclusive society. The brand\'s vision is to inspire positive change by promoting tolerance, love, and respect for gender diversity.As a fashion brand, Alter Designs aims to provide its customers with unique and innovative fashion pieces that are not only stylish but also sustainable. The brand is committed to reducing its environmental footprint by using eco-friendly materials and promoting responsible production practices.', 
    contribution: 'The Alter team contacted me to address two key issues: a complicated backend and the need for a coded prototype. After evaluating their old WordPress site, I identified three main problems: slow speed, poor user-friendliness, and a non-intuitive backend. To solve these, I migrated their inventory to a new Shopify-based e-commerce website. I adapted the given UX/UI prototype to be responsive across various devices with custom CSS. The result was a faster, user-friendly, and adaptable website.', 
    category: 'Fashion', 
    languages:['HTML, CSS, Javascript, Liquid'],
    linkUrl:'https://alter-designs.com', 
    secondImage: alter_design,
    thirdImage: alter_design1,
    designTitle: 'Elegance in Evolution: Crafting Alter Designs',
    designText: 'Alter Designs initially approached me with a foundational UX/UI concept. With an eye for detail and brand alignment, I tweaked this foundation into a reflection of the brand’s core tenets: Minimalistic yet Graphic, Elegance in the Details, Inclusivity, Hybridity, Responsibility, and Evolution. The timeless Helvetica font was chosen for its clear, readable, and modern aesthetic, consistently used throughout the website. The color palette, thoughtfully selected, included the soft neutrality of ivory and haze, complemented by the warmth of clay orange, all set against a clean white backdrop. This approach underlined the brand\'s ethos while delivering a visually appealing and user-centric experience.'

  },
  {
    id: 2,
    title: "ARTKOM Space",
    description: "Website Rebranding & Development",
    image: artkom, 
    link: "/artkom-space",
    overview: 'ARTKOM is an online marketplace that features a curated selection of avant-garde pieces from established and emerging artists and art galleries across the globe. Their offerings include a wide range of physical products, such as paintings and sculptures, as well as stunning digital art. Founded by two visionary entrepreneurs, ARTKOM is a trendy, dynamic company that has established a physical presence in Dubai, while also maintaining a strong online presence through their e-shop.',
    contribution: 'Seeking to better align their website with their brand, ARTKOM turned to me for assistance with re-branding. I tailored a modern design style for them, and redeveloped their original site on Squarespace. The redevelopment incorporated custom HTML, CSS, and Java, along with the integration of direct WhatsApp contact functionalities, ensuring the site aligned with their brand and modern appeal.',
    category: 'Art, Art Gallery',
    languages: ['HTML, CSS, Javascript'],
    linkUrl: 'https://artkomspace.com', 
    secondImage: artkom_design,
    thirdImage: artkom_design1,
      designTitle: 'Pop Art Meets Digital: Vibrancy in Every Pixel',
      designText: 'Diving into the vibrant world of Artkom Space, the digital design direction aimed to encapsulate the essence of modern pop art. Anchoring the design in a monochromatic palette of black, grey, and white, I introduced a splash of \'space yellow\' to infuse the vivacity and boldness that pop art embodies. By emphasizing visuals over words, each art piece take center stage, allowing visitors a direct, unobstructed vision of the gallery\'s offerings. The intriguing addition of text appearing only upon hover adds a playful and interactive element, ensuring the user experience is not just immersive but also fun. Merging modern design techniques with the vibrant spirit of pop art, I crafted a digital branding experience that resonates with art enthusiasts and the modern audience alike.'
  
  },
  {
    id: 3, 
    title: "Maria Pino",
    description: "Digital Rebranding",
    image: mp,
    link: "/maria-pino",
    overview: 'Maria Pino is an esteemed luxury fashion brand with its headquarters situated in the Middle East. The brand is renowned for offering a diverse range of high-end clothing items, bags, and elegant shoes.', 
    contribution:'Maria Pino required a website rebranding due to discrepancies in representation, user challenges, and technical hitches of their old website. As the sole developer and designer, I modernized the aesthetics, improved functionalities, and ensured the brand\'s image was front and center. From prototype design to final development, I integrated key features, including syncing the e-shop inventory with the brand\'s main inventory, aiming for a seamless and enriched user experience.',
    category:'Fashion',
    languages: ['HTML, CSS, Javascript, Liquid'],
    linkUrl:'https://maria-pino.com',
    secondImage: mp_design,
    thirdImage: mp_design1,
    designTitle: 'Mature Allure: Maria Pino\'s Digital Renaissance',
    designText: 'For the fashion brand Maria Pino, the digital design goal was clear - mature elegance in every detail. Keeping the sophisticated woman in mind, the website was revamped to exude class and maturity. The use of typeface like Montserrat imparted a touch of elegance, setting the tone for the brand\'s digital presence. Diving into a rich and poised color palette, I chose shades of mahogany and rose, balanced by the deep resonance of ink and the purity of white. Each design choice was made to ensure that the website not only captures Maria Pino\'s essence but also resonates with its clientele. It was a journey from a brand to a digital experience, personifying mature elegance.',

},
  
  {
    id: 4,

    title: "Mario Luca Giusti AE",
    description: "Website Design & Development",
    image: mlg,
    link: "/mlg",
 overview: 'Mario Luca Giusti AE is the official distributor of Mario Luca Giusti\'s luxury synthetic crystal products in the UAE. They bridge the Italian designer\'s brand with the local market, promoting the unique blend of traditional aesthetics and modern durability. Their role is pivotal in extending the brand\'s presence in the Middle Eastern luxury sector.', 
   contribution:'Mario Luca Giusti AE approached me to craft their website. I commenced by developing a prototype, followed by the full e-commerce platform. While staying true to the foundational branding of Mario Luca Giusti, I tailored it with custom elements to resonate with the UAE market and its distinct nuances.',
   category:'Tableware, Homeware',
   linkUrl:'https://mariolucagiusti.ae',
   languages: ['HTML, CSS, Javascript, Figma, Adobe AI'],
   secondImage: mlg_design,
  thirdImage: mlg_design1,
  designTitle: 'Art de la Table',
  designText: 'The design journey was marked by the use of warm and elegant colors, complemented by finely crafted illustrated assets. The aim was to craft a space where the tableware items shine in their full vibrancy, creating a delightful visual feast for the visitors. Each design choice was meticulously made to ensure the website exudes elegance, sophistication, and the vibrant essence of the Mario Luca Giusti brand.',
},

  {
    id: 5,
    title: "Time Mgmt",
    description: "Website Design & Development",
    image: time_mgmt,
    link: "/time_mgmt",
    overview: 'Time Mgmt is a dynamic digital marketing firm based in the Paris. As a young and energetic brand, they specialise in helping brands strengthen their online presence on social media through digital marketing. As a team, they combine creativity and strategy to deliver solutions that resonate with modern audiences. ', 
    contribution:'As Time Mgmt expanded, their outdated website no longer represented their advanced services. They engaged me for a digital rebrand. Diving into the brand\'s core, I designed a prototype merging modernity with Time Mgmt\'s youthful spirit. I utilized Flutter for development, offering an app-like design on a web platform, elevating user experience.',
    category:'Digital Marketing, Agency',
    languages: ['Flutter, Firebase, Figma, Adobe AI'],
    linkUrl:'https://time-mgmt.com',
    secondImage: time_mgmt_design,
    thirdImage: time_mgmt_design1,
    designTitle: 'Empower, Grow, Shine: A Digital Awakening',
    designText: 'The design palette took inspiration from the enduring symbol of the tulip, embodying confidence and continuous growth that resonates with every empowered woman\'s journey. Guided by the radiant spirit of the agency\'s owner, hailing from the sun-kissed realms of southern France, the chosen colors spoke a vivid language. A sunlit orange reminiscent of the southern sun, complemented by an earthy green symbolizing sustainability, was set against the backdrop of a contrasting greige. This not only highlighted the vibrancy but also added a touch of contemporary sophistication. The entire design vibe was consciously kept modern, playful, and youthful, mirroring the core essence of this digital marketing agency.'
  },
  

//  {id: 6, 
//    title: "Lamin Air",
//  description: "Branding & Website Development",
//  image: lamin_air, 
//  link: "/lamin-air",
//  overview: 'Lamin Air is a company in the HVAC domain. Their expertise spans from the residential sector to commercial and institutionals. Their serivices extends beyond just installation; they also serve as a partner for maintaining systems, ensuring a comfortable and healthy environment for occupants.',
//  contribution: 'Lamin Air recently embarked on its journey and sought my expertise for branding their company — from font selections to color palettes. In addition to crafting their company\'s brand identity, I was entrusted with the task of developing their company website and designing flyers to start their venture. This remains an ongoing project.',
//  category: 'HVAC and Building Mechanics',
//  languages: ['HTML, CSS, Figma, Adobe AI'],
//  linkUrl: 'https://lamin-air.com', 
//  secondImage: laminair_design,
//  thirdImage: laminair_design1,
//  designTitle: 'Embracing the Breeze: Designing for Lamin Air',
//designText: 'For Lamin Air, an HVAC company, wind waves are the foundation of my design inspiration. The website gracefully embodies wavy patterns, symbolizing the flow and purity of air. A "breathable" design strategy was employed throughout. Complementing this airy aesthetic, I selected Poppins and Roboto fonts, both known for their well-rounded and calming presence. Their functional nature aligns perfectly with the brand’s essence. The color palette was meticulously chosen to resonate with the air element, employing various shades of blue and grey. These hues evoke feelings of freshness and tranquility, and also capture the core services and look & feel of Lamin Air.'

//},
{
  id: 7,
  title: "NOS",
  description: "Application Development",
  image: nos,
  link: "/nos",
  overview: 'Neurocare of the South is a leading medical company that operates multiple neurorehabilitation clinics throughout the United States. They specialize in the diagnosis and treatment of a wide range of brain, spinal cord, nerve, and muscle disorders, utilizing a team of highly trained specialists to ensure that patients receive the care and comfort during their journey to recovery.', 
  contribution:'Neurocare of the South aimed to enhance their digital reach to serve patients better. In response, I developed an application with a medical aesthetic, providing users with information on clinics, treatments, and NeuroCare services. Additionally, I integrated a reservation feature for doctor appointment bookings.',
  category:'Medical',
  languages: ['Flutter, Firebase'],
  linkUrl:'https://apps.apple.com/fr/app/neurocare-of-louisiana/id1516271239',
  secondImage: nos_design,
  thirdImage: nos_design1,
  designTitle: 'Medically Modern: Crafting Clarity for Neurocare of the South',
designText: 'For Neurocare of the South, I prioritized clarity and precision, essential traits for a medical company. The design is uncomplicated, relying on rounded elements and information-centric layouts. Direct, concise sentences were used to convey vital information without overwhelming the user. Its aesthetic shows a modern medical ambiance, reflecting trustworthiness and professionalism. The font choice of Montserrat reinforced the modern appeal, with its strict, crisp lines. A gradient ranging from clear blue to a deeper medical blue was combined with shades of grey and white, not only establishing an authentic medical feel but also ensuring readability and clarity throughout the website.'

},
 
{
  id: 8,
  title: "Teal Editions",
  description: "Website Design & Development",
  image: teal_editions,
  link: "/teal-editions",
  overview: 'Teal Productions is an art company that collaborates with well-known artists like Samir Sayegh, Hussein Madi, Mohammad al Rawas, and others to produce limited edition prints. Their prints are high-quality reproductions of the original works on canvas or paper, with the permission of the artist. ', 
  contribution:'When I started with Teal Productions, they had a showroom but lacked an online presence. I guided their branding, from color palettes to logo design, and developed their website\'s UX/UI. I also designed a prototype, managed back-end operations like inventory automation, and integrated features.',
  category:'Art, Art Gallery',
  languages: ['HTML, CSS, JavaScript, Figma, Adobe AI'],
  linkUrl:'https://tealeditions.com',
  secondImage: teal_design,
  thirdImage: teal_design1,
  designTitle: 'Squared Elegance: A Teal Tapestry of Art',
designText: 'Drawing inspiration from the vibrant hue of "Teal", the design approach for Teal Editions aimed to blend playful charm with structured elegance. A gradation of teal shades, accentuated by refined hints of clear beige, encapsulates the website\'s canvas. Ovo and Times fonts, with their distinct personalities, add depth and sophistication to the site\'s textual elements. Geometric precision, showcased through a grid of squares, reflecting the gallery\'s curated collection of prints. Each square, meticulously placed, provides potential buyers a glimpse into the exquisite art pieces available. The design effectively bridges the allure of art with the clarity of structure, providing an immersive gallery experience online.'

},
  {
    id: 9,
    title: "Linkera",
    description: "Website Design & Development",
    image: linkera, 
    link: "/linkera",
    overview: 'Linkera is a platform and network that eases the connection between digital business owners with buyers. Their mission revolves around forging a secure and user-friendly environment where business transactions are simplified, and commercial opportunities come to fruition.',
    contribution: 'Dissatisfied with the original website, the owner approached me for a revamp. Entrusted with a wireframe, I conceptualized and developed the UI, and upon approval, transformed their existing WordPress site.',
    category: 'Business, Tech, Transactions',
    languages: ['HTML, CSS, Elementor, Figma, Adobe AI'],
    linkUrl: 'https://linkera.com', 
    secondImage: linkera_design,
    thirdImage: linkera_design1,
    designTitle: 'Likera: Bridging Digital Dreams',
designText: 'For Likera, a player in the domain of connecting web and startup enthusiasts, the design needed to be vibrant, digital, and intuitive. Drawing inspiration from the tech-centric nature of the brand, I chose a graphical approach, integrating a mix of icons, geometric illustrations, and digital depictions of their software. The color palette was chosen to resonate with the tech world, featuring hues of sky and teal, punctuated with zesty lemon accents to introduce contrast and energy. Lufga, a contemporary font, was chosen to complement the entire digital look & feel, reinforcing the brand’s commitment to innovation and connectivity in the digital space.'

  },
 

  
  
  
];