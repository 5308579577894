// Projects.jsx
import React, { useEffect } from 'react';
import { projects } from '../data/projects'
import styles from './Projects.module.scss';
import Card from './Card';

export default function Projects() {
  useEffect(() => {
    projects.forEach(project => {
      const img = new Image();
      img.src = project.image;
    });
  }, []);

  return (
    <div className={styles.projectsContainer}>
      {projects.map((project, index) => (
        <Card key={index} project={project} />
      ))}
    </div>
  );
}
