import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navbar from './Components/Navigation/Navbar'
import Header from './Components/Header/Header';
import Projects from './Components/Projects/Projects';
import Content from './Components/Header/Content';
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import ProjectDetail from './Components/Projects/ProjectDetail';
import ContactBackground from './Components/Contact/ContactBackground';

export const App = () => (
  <Router>
    <Navbar />
    <Routes>
      <Route path="/" element={<><Content /><Header /></>} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<><ContactBackground /><Contact /></>} />
      <Route path="/project-detail" element={<ProjectDetail />} />
    </Routes>
  </Router>
);

