import styles from './Navbar.module.scss';
import { motion } from "framer-motion";
import { useState } from "react";
import { FiMenu, FiArrowRight } from "react-icons/fi";
import elka_logo from '../images/elka_logo.png';
import { useLocation, Link } from 'react-router-dom';



const FlipNavWrapper = () => {
  const location = useLocation();
  const isIndex = location.pathname === '/' || location.pathname === '/contact';

  return (
    <div className={`${styles.flipNavWrapper} ${isIndex ? styles.indexPage : ''}`}>
      <FlipNav isIndex={isIndex} />
      <div className={styles.spacer}/>
    </div>
  );
};


const FlipNav = ({ isIndex }) => { // Receiving isIndex as a prop here

  const [isOpen, setIsOpen] = useState(false);
  return (
    <nav className={styles.flipNav}>
      <NavLeft setIsOpen={setIsOpen} />
      <div className={styles.rightContent}>
      </div>
      <NavMenu isOpen={isOpen} isIndex={isIndex} /> {/* Passing isIndex to NavMenu here */}
    </nav>
  );
};


const Logo = () => {
  return (
    <Link to='/'> 
      <img src={elka_logo} alt="ELKA Logo" />
    </Link>
  );
};


const NavLeft = ({ setIsOpen }) => {
  return (
    <div className={styles.navLeft}>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className={styles.navLeftButton}
        onClick={() => setIsOpen((pv) => !pv)}
      >
        <FiMenu />
      </motion.button>
      <Logo />
      <NavLink text="Projects" />
      <NavLink text="Contact" />
    </div>
  );
};

const NavLink = ({ text }) => {
  const paths = {
    "Projects": "/projects",
    "Contact": "/contact",

  };
  return (
    <a
    href={paths[text] || "#"}
      rel="nofollow"
      className={styles.navLink}
    >
      <motion.div whileHover={{ y: -30 }}>
   
        <span className={styles.blacktextSpan}>
          {text}
        </span>
      </motion.div>
    </a>
  );
};

// const NavRight = () => {
//   return (
//     <div className={styles.navRight}>
//       <motion.button
//         whileHover={{ scale: 1.05 }}
//         whileTap={{ scale: 0.95 }}
//         className={styles.navRightButton}
//       >
//         Sign in
//       </motion.button>
//       <motion.button
//         whileHover={{ scale: 1.05 }}
//         whileTap={{ scale: 0.95 }}
//         className={styles.signInButton}
//       >
//         Sign up
//       </motion.button>
//     </div>
//   );
// };

const NavMenu = ({ isOpen, isIndex }) => {
  return (
    <motion.div
    variants={menuVariants}
    initial="closed"
    animate={isOpen ? "open" : "closed"}
    className={`${styles.navMenuOpen} ${isIndex ? styles.indexPage : ''}`}
  >
    <MenuLink text="Projects" />
    <MenuLink text="Contact" />
  </motion.div>
  
  );
};

const MenuLink = ({ text }) => {
  const paths = {
    "Projects": "/projects",
    "Contact": "/contact",

  };

  return (
    <motion.a
    variants={menuLinkVariants}
      rel="nofollow"
      href={paths[text] || "#"}
      className={styles.menuLinks}
    >
      <motion.span variants={menuLinkArrowVariants}>
      </motion.span>
      <motion.div whileHover={{ x: 30 }}>
        <span className={styles.menuLinksItems}>{text}</span>
    
      </motion.div>
    </motion.a>
  );
};

export default FlipNavWrapper;

const menuVariants = {
  open: {
    scaleY: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  closed: {
    scaleY: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.1,
    },
  },
};

const menuLinkVariants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: -10,
    opacity: 0,
  },
};

const menuLinkArrowVariants = {
  open: {
    x: 0,
  },
  closed: {
    x: -4,
  },
};