import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './ProjectDetails.module.scss'
import Button from '../Button/Button';

const ProjectDetail = () => {
  const location = useLocation();
  const project = location.state.project;


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <>
    <div className={styles.projectDetail}>
      <div className={styles.banner}>
        <img src={project.image} alt="hero_img" />
      </div>
      <div className={styles.contentRow}>
                <div className={styles.projectOverview}>
                    <h3>{project.title}</h3>
                    <p>{project.overview}</p>
                </div>
                
                <div className={styles.projectContributions}>
                    <h3>Contributions</h3>
                    <p>{project.contribution}</p>
                    <div className={styles.contentRowDetail}>
                        <div className={styles.projectToolset}>
                            <h3>Toolset</h3>
                            <p>{project.languages}</p>
                        </div>

                        <div className={styles.projectTags}>
                            <h3>Tags</h3>
                            <p>{project.category}</p>
                        </div>
                    </div>
                    
                </div>
            </div>
            <hr className={styles.fullWidthLine} />
      <div className={styles.projectDesign}>
      <h3>{project.designTitle}</h3>
      <p>{project.designText}</p>
        <Button name="Open in Browser" onClick={() => project?.link && window.open(project.linkUrl, '_blank')} />
      </div>
    </div>
    <hr className={styles.fullWidthLine} />
    <div className={styles.imageRow}>
    <div className={styles.secondImage}>
        <img src={project.secondImage} alt="project_img" />
      </div>

      <div className={styles.thirdImage}>
        <img src={project.thirdImage} alt="project_img" />
      </div>
    </div>
  </>
  );
};

export default ProjectDetail;
