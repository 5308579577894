import React, {useRef} from 'react';
import styles from './About.module.scss'
import { motion } from 'framer-motion';

export default function About() {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
        <TextSection />
    </motion.div>
);
}

const containerVariants = {
hidden: { opacity: 0 },
visible: { opacity: 1, transition: { staggerChildren: 0.2 } }
};

const childVariants = {
hidden: { y: 50, opacity: 0 },
visible: { y: 0, opacity: 1, transition: { duration: 0.5 } }
};
    
    function TextSection() {
      return (
        <motion.div className={styles.text} variants={containerVariants} initial="hidden" animate="visible">
        <motion.div className={styles.skillContainer} variants={childVariants}>

            <div className={styles.skillsSection}>
              <h5>Story</h5>
            </div>
            <div className={styles.skillDescription}>
              <h2>About</h2>
              <p>
                I&apos;ve always been tech-savvy from a young age. As a child, you&apos;d often find me glued to my computer
                screen, much to my mother&apos;s chagrin as she urged me to go outside and enjoy the sunshine. Despite living
                in a sunny country, my skin tone resembled #FFFFFF, a testament to my dedication to all things digital.
              </p>
              <div>
              <p>
                I like to think of myself as a blend of logical thinking and daydreaming. I find joy in both structured
                problem-solving and creativity. It was during my young years that I first dipped my toes into the world of
                coding and web design, and it didn&apos;t take long for me to realize that I had stumbled upon my true
                passion.
              </p>
              </div>
    
              <div>
              <p>
                My journey into the world of web development and design began as a cherished pastime, but it took on new
                significance during the pandemic. Suddenly, I found myself coding not just for personal pleasure, but for
                others as well, taking on diverse projects that challenged and inspired me.
              </p>
              </div>
    
              <div>
              <p>
                What truly excites me is the opportunity to be a digital storyteller. I revel in the process of crafting a
                unique digital identity for a brand, meticulously selecting colors and fonts, and transforming concepts into
                fully functional interactive experiences. My portfolio showcases a diverse range of projects, from
                collaborating with medical professionals to enhancing the aesthetics of the fashion industry.
              </p>
              </div>
    
              <div>
              <p>
                In a world where the digital landscape is ever-evolving, I find fulfillment in bringing innovation and
                creative solutions to life. I&apos;m always ready to take on new challenges and translate ideas into impactful
                digital realities. Explore my portfolio to see the stories I&apos;ve helped create and the brands I&apos;ve had
                the privilege to bring to life.
              </p>
              </div>
              <div>
              <p>Let&apos;s embark on a journey of innovation and creativity together.</p>
              </div>
            </div>
    
          </motion.div>
    
          <div>
            <div className={styles.skillContainer}>
              <div className={styles.skillsSection}>
                <h5>Code</h5>
              </div>
              <div className={styles.skillDescription}>
                <h2>What I use to code</h2>
                <p>HTML / CSS / JavaScript / Flutter / React / Next / Firebase / Google Cloud / Git</p>
              </div>
            </div>
          </div>
    
          <div>
            <div className={styles.skillContainer}>
              <div className={styles.skillsSection}>
                <h5>Design</h5>
              </div>
              <div className={styles.skillDescription}>
                <h2>What I use to design</h2>
                <p>Figma / Adobe Illustrator / Webflow / Flutterflow / Marvel / Photoshop</p>
              </div>
            </div>
          </div>
        </motion.div>
      );
    }

