import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import styles from "./Card.module.scss";
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Card = ({ project }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isImageLoaded, setImageLoaded] = useState(false);
  const [imageStyle, setImageStyle] = useState({
    height: "100%",
    width: "24vw",
    objectFit: "cover"
  });

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setImageStyle({
          height: "100%",
          width: "46vw",
          objectFit: "cover"
        });
      } else {
        setImageStyle({
          height: "100%",
          width: "24vw",
          objectFit: "cover"
        });
      }
    }

    handleResize(); // call the function initially to set the styles based on the initial window width
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = () => {
    navigate('/project-detail', { state: { project } });
  }

  return (
    <motion.div
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onTouchStart={() => setIsHovered(true)}
      onTouchEnd={() => setIsHovered(false)}
      className={styles.card}
      style={{
        filter: isHovered ? 'grayscale(0%)' : 'grayscale(100%)',
        scale: isHovered ? 1.05 : 1 
      }}
      transition={{ duration: 0.3 }}
    >
      {!isImageLoaded && <div className={styles.cardLoading}></div>}
      <LazyLoadImage src={project.image} 
        onLoad={() => setImageLoaded(true)} 
        alt={project.title} 
        effect='blur'
        placeholderSrc={project.image}
        style={imageStyle}
      />
      <div className={styles.cardDetail}>
        <h3 className={styles.cardTitle}>{project.title}</h3>
        <p className={styles.cardDescription}>{project.description}</p>
      </div>
    </motion.div>
  );
};

export default Card;
