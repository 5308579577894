import React from 'react';
import Button from '../Button/Button';
import styles from './Contact.module.scss';

import { CiMail } from 'react-icons/ci';
import { AiOutlineInstagram } from 'react-icons/ai';


export default function Contact() {
  const handleEmailClick = () => {
    const mailtoLink = `mailto:elkhouryadvisory@gmail.com`;
    window.location.href = mailtoLink;
  };

  return (
    <div className={styles.contact}>
    <div className={styles.contactContainer}>
            <h1>Get in Touch</h1>
            <p>Whether you have a question or just want to say hi, I&apos;d love to hear from you.</p>
            <div className={styles.buttonRow}>
            <Button name="Send me an email" icon={<CiMail />} onClick={handleEmailClick} />
            <Button name="See my Instagram" icon={<AiOutlineInstagram />} onClick={() => window.open("https://instagram.com/elka_dev?igshid=MzRlODBiNWFlZA==", "_blank")}
/>

                </div>
        </div>
        </div>
  )
}

