import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';


const Button = ({ name, icon, onClick }) => {
  return (
    <div className={styles.btnContainer} onClick={onClick}>
      <NeumorphismButton name={name} icon={icon} />
    </div>
  );
};

const NeumorphismButton =  ({ name, icon }) => {
  return (
    <button className={styles.neumorphismButton}>
      {name}
      {icon && icon}
    </button>
  );
};

Button.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func
};

export default Button;
